import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashbord from "./page/Dashbord";
import Header from "./page/Header";
import Deposit from "./page/Deposit";
import Portfolio from "./page/Portfolio";
// import { WagmiConfig } from "wagmi";
import {
  connectorsForWallets,
  getDefaultWallets,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import Trade from "./page/Trade";

import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import Depositbnb from "./page/Depositbnb";
import Depositarb from "./page/Depositarb";
import { Provider } from "react-redux";
import store from "./page/redux/store";
import User from "./page/User";
import Registration from "./page/Registration";
import DepositWYZfifty from "./page/DepositWYZfifty";
import DepositWYZseventy from "./page/DepositWYZseventy";
import Depositusdt from "./page/Depositusdt";
import Calculator from "./page/Calculator";
import Announcement from "./page/Annoucement";
import {
  injectedWallet,
  metaMaskWallet,
  rabbyWallet,
  rainbowWallet,
  safepalWallet,
  trustWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";

const avalanche: Chain = {
  // id: 309,
  id: 303,
  name: "WYZth Scan",
  network: "WYZth Scan",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "WYZ",
    symbol: "WYZ",
  },
  rpcUrls: {
    // public: { http: ["https://rpc-mainnet.wyzthchain.org/"] },
    // default: { http: ["https://rpc-mainnet.wyzthchain.org/"] },
    public: { http: ["https://rpc-mainnet.wyzthchain.org/"] },
    default: { http: ["https://rpc-mainnet.wyzthchain.org/"] },
  },
  blockExplorers: {
    default: { name: "WYZth Scan", url: "https://mainnet.wyzthscan.org/" },
    etherscan: { name: "WYZth Scan", url: "https://mainnet.wyzthscan.org/" },
  },

  testnet: false,
};

const { chains, publicClient } = configureChains(
  [avalanche],
  [alchemyProvider({ apiKey: process.env.ALCHEMY_ID }), publicProvider()]
);

// const { connectors } = getDefaultWallets({
//   appName: "My RainbowKit App",
//   projectId: "YOUR_PROJECT_ID",
//   chains,
//   wallets: [rabbyWallet],
// });

const projectId = "YOUR_PROJECT_ID";

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      injectedWallet({ chains }),
      rainbowWallet({ projectId, chains }),
      walletConnectWallet({ projectId, chains }),
      metaMaskWallet({ projectId, chains }),
      rabbyWallet({ projectId, chains }),
      trustWallet({ projectId, chains }),
      safepalWallet({ projectId, chains }),
    ],
  },
]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WagmiConfig config={wagmiConfig}>
    <RainbowKitProvider chains={chains} modalSize="compact">
      {/* <React.StrictMode> */}
      <Provider store={store}>
        <BrowserRouter>
          {/* <Announcement /> */}
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/app" element={<Dashbord />} />
            <Route path="/deposit" element={<Deposit />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/bnb" element={<Depositbnb />} />
            <Route path="/arb" element={<Depositarb />} />
            <Route path="/dashboard" element={<User />} />
            <Route path="/signup" element={<Registration />} />
            <Route path="/wysfifty" element={<DepositWYZfifty />} />
            <Route path="/wysseventy" element={<DepositWYZseventy />} />
            <Route path="/usdt" element={<Depositusdt />} />
            <Route path="/calculator" element={<Calculator />} />
          </Routes>
        </BrowserRouter>
      </Provider>
      {/* </React.StrictMode> */}
    </RainbowKitProvider>
  </WagmiConfig>
);
