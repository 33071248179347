import React, { useState, useEffect } from "react";
import { GoArrowDown } from "react-icons/go";
import "@rainbow-me/rainbowkit/styles.css";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import {
  setAllStakes,
  setAlphaId,
  setClaimedReward,
  setTotalStake,
  setWalletId,
} from "./redux/reducer";
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { setRefAddress } from "./redux/reducer";
import { checkUser, idToAddres } from "./web3/getWeb3";
import { disconnect } from "@wagmi/core";
import { useAccount } from "wagmi";
import { Link, redirect, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import { localhost_api } from "./web3/config";
import { getUserStake } from "./web3/api";
import Annoucement from "./Annoucement";
import { toast } from "react-hot-toast";
import { Toaster } from "react-hot-toast";

const Header = () => {
  const [count, setCount] = useState(true);
  const dispatch = useDispatch();
  const { refAddress } = useSelector((state) => state.wallet);
  const { walletID } = useSelector((state) => state.wallet);
  const { address } = useAccount();

  const [dashboard, setDashboard] = useState(null);
  useEffect(() => {
    const dashboardData = async () => {
      try {
        const response = await axios.get(localhost_api + "dashboard", {
          params: {
            user: walletID,
          },
        });

        if (response.data.data) {
          setDashboard(response.data.data);
          dispatch(setTotalStake(response.data.data.wysStaked / 1e18));
          const claimedReward1 =
            (response.data.data.directBonus + response.data.data.levelBonus) /
            1e18;
          dispatch(setClaimedReward(claimedReward1));
        } else {
          setDashboard(0);
          dispatch(setTotalStake(0));
        }
      } catch (error) {
        // Handle errors here
        console.log("Error fetching data:", error);
      }
    };

    dashboardData();
  }, [walletID]);

  useEffect(() => {
    const setReferral = async () => {
      // console.log("address step1",address)
      if (address) {
        try {
          const response = await axios.get(localhost_api + "refreal", {
            params: {
              wallet_add: address,
            },
          });
          if (response.data.status === 200 && response.data.referrerId != "") {
            try {
              const res = await axios.get(localhost_api + "idToAddress", {
                params: {
                  userId: response.data.referrerId,
                },
              });
              dispatch(setRefAddress(res.data.data?.user));
            } catch (err) {
              console.log(err);
            }
          } else {
            const r = await idToAddres(1);
            if (r !== "") {
              dispatch(setRefAddress(r));
              console.log(r, "set date");
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    };

    setReferral();
  }, [address, dispatch]);

  const dropdown = () => {
    if (count == true) {
      setCount(false);
    } else {
      setCount(true);
    }
  };

  useEffect(() => {

    const adressToID = async () => {
      try {
        const response = await axios.get(localhost_api + "addressToId", {
          params: {
            user: address,
          },
        });

        if (response.data.status == 500) {
          dispatch(setAlphaId(0));
        } else {
          dispatch(setAlphaId(response.data.data?.userId));
        }
      } catch (err) {
        console.log(err);
      }
    };
    adressToID(address);

  }, [address]);

  

  useEffect(() => {
    if (address) {
      getUserStake(address).then((res) => {
        if (res?.status == 200) {
          dispatch(setAllStakes(res.data.data));
        }
      });
    }
  }, [address]);

  const redirectUser = async () => {
    try {
      // toast.success("Please Wait We are Authorizing You");
      if (!address) {
        return toast.error("Please connect your wallet");
      }
      const res = await checkUser(address);
      toast.success("Please Wait We are Authorizing You", {
        duration: 5000,
      });
      const token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6IjEyIiwiZmlyc3RfbmFtZSI6IkFyY2hpdCIsImxhc3RfbmFtZSI6ImEiLCJkb2IiOiIxNzI2NTExNDAwMDAwIiwiZ2VuZGVyIjoiTWFsZSIsInByb2ZpbGVfcGljIjoibWVkaWEvdXNlcl9wcm9maWxlXzE3MjY5NTA4NjAuanBlZyIsImpvaW5lZF9vbiI6IjE3MjY5NTY1NjcwMDAiLCJsZWFkX3N0YXR1cyI6IiAiLCJ1c2VyX3N0YXR1cyI6ImNsb3NlZC13b24iLCJyZWZlcmVuY2UiOiIwIiwib3duZXIiOiIxIiwicmVtYXJrcyI6InJlbWFya3MiLCJwaG9uZSI6IjkxMjI2Nzg4NDQiLCJlbWFpbCI6ImVtYWlsQGdtYWlsLmNvbSIsInN0YXRlIjoiUmFqYXN0aGFuIiwiYXJlYSI6IkRvbnQgUm9hZCIsImNpdHkiOiJKYWlwdXIiLCJwaW5jb2RlIjoiMzAyMDE4IiwiaW50ZXJlc3QtcHJvdG9jb2wiOiIxIiwiZGlzcG9zaXRpb24iOiIxMiIsImFtb3VudCI6IjEyMzQ1Iiwid2FsbGV0X2FkZHJlc3MiOiIiLCJtYXN0ZXJfcmVmZXJlbmNlX2lkIjoiIiwibWFzdGVyX3VzZXJfaWQiOiJXWVNDVkFfVSIsImV4cGlyeSI6MTczMTU3MzE3M30.3hf3ThxB24j6Xfu5_oq0xlK_1KWcuUCiamrFvW4oDRU";
      if (res) {
        const resApi = await axios.put(
          "https://dms.wyscale.com:3000/api/v1/auth",
          {
            user: dashboard.user,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        // console.log(resApi, resApi?.data?.payload?.JWT_Token, "::::resApi");
        const vToken = resApi?.data?.payload?.JWT_Token;
        console.log(resApi.data.payload?.JWT_Token,"::::123");
        if (vToken) {
          window.location.href = "https://dms.wyscale.com/?authToken=" + vToken;
        } else {
          toast.error("User not verified");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Toaster />
      <div className="row" id="header" style={{ margin: "0px !important" }}>
        <div
          className="row col-lg-12"
          style={{
            height: "40px",
            background: "#171717",
          }}
        >
          <div
            className="col-lg-11 m-auto"
            style={{ position: "relative", display: "flex" }}
          >
            <select
              name=""
              id=""
              style={{
                background: "transparent",
                color: "white",
                outline: "none",
                border: "none",
                display: "inline-block",
              }}
              // className="border"
            >
              <option
                value=""
                style={{ background: "transparent", color: "white" }}
              >
                Individual Investor
              </option>
            </select>

            <div
              style={{
                display: "inline-block",
                position: "absolute",
                right: 5,
                color: "white",
              }}
              // className="border"
            >
              <span>Download App</span>
              <span>
                <GoArrowDown />
              </span>
            </div>
          </div>
        </div>

        <nav class="navbar navbar-expand-lg bg-body-tertiary top-fluid">
          <div
            class="container-fluid navc"
            style={{ width: "92%", margin: "auto" }}
          >
            <a class="navbar-brand " href="/">
              <img
                src="image/t Text.svg"
                height="60"
                width="100%"
                alt=""
                class="mb-2"
              />
            </a>
            <div class="navbar-brand" style={{ marginRight: "0px" }}>
              <div>
                <div
                  class="connect-btn-contact"
                  style={{
                    border: "2px solid #f6e363",
                    background: "transparent",
                  }}
                >
                  <div
                    class="connect-content fw-bolder"
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    <ConnectButton.Custom>
                      {({
                        account,
                        chain,
                        openAccountModal,
                        openChainModal,
                        openConnectModal,
                        authenticationStatus,
                        mounted,
                      }) => {
                        const ready =
                          mounted && authenticationStatus !== "loading";
                        const connected =
                          ready &&
                          account &&
                          chain &&
                          (!authenticationStatus ||
                            authenticationStatus === "authenticated");
                        if (account && account.address !== undefined) {
                          dispatch(setWalletId(account.address));
                        }
                        return (
                          <div
                            {...(!ready && {
                              "aria-hidden": true,
                              style: {
                                opacity: 0,
                                pointerEvents: "none",
                                userSelect: "none",
                              },
                            })}
                          >
                            {(() => {
                              if (!connected) {
                                return (
                                  <div onClick={openConnectModal}>
                                    <span
                                      style={{
                                        background:
                                          "linear-gradient(45deg, #8fd41f 0%, #ffe569 40%)",
                                        WebkitBackgroundClip: "text",
                                        WebkitTextFillColor: "transparent",
                                      }}
                                    >
                                      Connect Wallet
                                    </span>
                                  </div>
                                );
                              }

                              if (chain.unsupported) {
                                return (
                                  <div
                                    onClick={openChainModal}
                                    // type="button"
                                    style={{
                                      background:
                                        "linear-gradient(45deg, #8fd41f 0%, #ffe569 40%)",
                                      WebkitBackgroundClip: "text",
                                      WebkitTextFillColor: "transparent",
                                    }}
                                  >
                                    Wrong network
                                  </div>
                                );
                              }

                              return (
                                <div style={{ display: "flex", gap: 12 }}>
                                  <div
                                    style={{
                                      background:
                                        "linear-gradient(45deg, #8fd41f 0%, #ffe569 40%)",
                                      WebkitBackgroundClip: "text",
                                      WebkitTextFillColor: "transparent",
                                    }}
                                    onClick={dropdown}
                                  >
                                    {account.displayName}
                                  </div>
                                </div>
                              );
                            })()}
                          </div>
                        );
                      }}
                    </ConnectButton.Custom>{" "}
                  </div>
                </div>

                <div
                  className="dropdown1"
                  style={{ display: count ? "none" : "block" }}
                >
                  {/* <Link
                    to="/signup"
                    style={{
                      background: "#3a3a3d",
                      borderTopLeftRadius: "5px",
                      borderTopRightRadius: "5px",
                      color: "#fffc",
                      fontWeight: "600",
                    }}
                  >
                    Register
                  </Link> */}
                  <Link to="/portfolio">Portfolio</Link>
                  <Link
                    to="/dashboard"
                    style={{ color: "#fffc", fontWeight: "600" }}
                    // target="_blank"
                  >
                    Dashboard
                  </Link>
                  {/* <Link
                    onClick={() => {
                      redirectUser();
                    }}
                    style={{ color: "#fffc", fontWeight: "500" }}
                  >
                    CRM
                  </Link> */}
                  {/* <Link to="/calculator">Calculator</Link> */}

                  <Link
                    href="javascript:void(0)"
                    onClick={async () => {
                      await disconnect();
                      await dropdown();
                    }}
                    style={{ color: "#fffc", fontWeight: "600" }}
                  >
                    Disconnect
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
