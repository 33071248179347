import React, { useState, useEffect } from "react";
import Header from "./Header";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import Pagination from "@mui/material/Pagination";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { claimRefereal, rewardInfo, wysPrice } from "./web3/getWeb3";
import { useAccount } from "wagmi";
import { localhost_api } from "./web3/config";
import { getreward } from "./web3/api";

const Portfolio = () => {
  const walleid = useSelector((state) => state.wallet.alphaId);

  const copyText = async (textToCopy) => {
    if (navigator.clipboard && window.isSecureContext) {
      await navigator.clipboard.writeText(textToCopy);
      toast.success("Referral Copied");
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand("copy");
        toast.success("Referral Copied");
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  };

  const handleCopyClick = (dynamicText) => {
    copyText(dynamicText);
  };

  const { claimedReward } = useSelector((state) => state.wallet);
  const [wys, setWys] = useState();
  const { totalStake } = useSelector((state) => state.wallet);
  useEffect(() => {
    wysPrice().then((res) => {
      var amt = Number(res) / 1e18;
      setWys(amt);
    });
  });

  const { walletID } = useSelector((state) => state.wallet);
  const { address } = useAccount();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalStakeList, setTotalStakeList] = React.useState(0);
  const [count, setCount] = useState(0);

  const [userStakeData, setUserStakeData] = useState(null);

  const fetchStakeData = async (page) => {
    try {
      const response = await axios.get(localhost_api + "stake-list", {
        params: {
          user: walletID,
          page: page,
          limit: itemsPerPage,
        },
      });

      // console.log(response, ":::::::::::::::::::::::");
      // console.log(response.data.totalDataCount);

      if (response.data.data !== undefined) {
        setUserStakeData(response.data.data);
        setTotalStakeList(response.data.totalDataCount);
        const totalItems = response.data.totalDataCount;

        const calculatedTotalPages = Math.ceil(totalItems / itemsPerPage);
        setTotalPages(calculatedTotalPages);
      } else {
        // If data is null or undefined, set default values or handle accordingly
        setUserStakeData([]);
        setTotalStakeList(0);
        // setPage(1);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const handleStakePageChange = (event, value) => {
    setCurrentPage(value);
    fetchStakeData(value);
  };
  useEffect(() => {
    if (walletID) {
      fetchStakeData(currentPage); // Fix the function name here
    }
  }, [walletID, currentPage]);

  // const [claimaibleReward, setClaimaibleReward] = useState(0);
  // const [rewardInfo1, setRewardInfo1] = useState([]);
  // const [withdrawnInfo, setWithdrawnInfo] = useState(0);
  // useEffect(() => {
  //   if (address) {
  //     const dashboardData = async () => {
  //       try {
  //         const response = await axios.get(localhost_api + "dashboard", {
  //           params: {
  //             user: address,
  //           },
  //         });

  //         if (response) {
  //           setDashboard(response.data.data);
  //         }
  //       } catch (error) {
  //         console.log("Error fetching data:", error);
  //       }
  //     };
  //     dashboardData();
  //   }
  // }, [walletID]);

  const claimref = async () => {
    const res = await getreward(address);
    console.log(res, "getReward");
    if (res) {
      if (res.data?.status == 200) {
        toast.success(res?.data?.message);
      } else {
        toast.error(res.data?.message);
      }
    } else {
      toast.error("Transaction failed");
    }
  };

  //console.log(address,"Adress from useAccount");

  const [dashboard, setDashboard] = useState(null);

  useEffect(() => {
    if (address) {
      const dashboardData = async () => {
        try {
          const response = await axios.get(localhost_api + "dashboard", {
            params: {
              user: address,
            },
          });
          console.log(response, ";;;");
          if (response?.data?.status == 200) {
            setDashboard(response.data?.data);
          }
        } catch (error) {
          console.log("Error fetching data:", error);
        }
      };
      dashboardData();
    }
  }, [address]);

  return (
    <>
      <Header />
      {/* <ToastContainer /> */}
      <Toaster />

      <div
        className="row col-lg-11"
        style={{
          borderRadius: "21px",
          background: "var(--Gray-900, #171717)",
          margin: "20px auto ",
          padding: "5px",
        }}
      >
        <div className="row col-lg-11 deposit " style={{ margin: "20px auto" }}>
          <div className="col-lg-2 deposit1">
            <p>Deposits</p>
            <h6>$ {(totalStake * wys).toFixed(3)} </h6>
          </div>
          <div className="col-lg-2 deposit1">
            <p>Claimable Rewards</p>
            <h6>
              {(dashboard?.availabelReward / 1e18)?.toFixed(4) > 0
                ? (dashboard?.availabelReward / 1e18)?.toFixed(4)
                : 0}{" "}
              WYS
            </h6>
          </div>
          <div className="col-lg-2 deposit1">
            <p>Referral Link</p>
            <p>Click on link to copy</p>
            <h6
              style={{ cursor: "pointer", fontSize: "20px" }}
              onClick={() =>
                handleCopyClick(
                  `https://registration.wyscale.com?refID=${walleid}`
                )
              }
            >
              {}
              {`http://...?refID=${walleid}`}{" "}
              <i
                className="fa fa-copy"
                style={{ cursor: "pointer", fontSize: "20px" }}
              ></i>
            </h6>
          </div>
          <div className="row col-lg-2 deposit1">
            <p>$ WYS Token</p>
            <a
              href="https://www.xt.com/en/trade/wys_usdt"
              target="_blank"
              className="col-lg-6"
            >
              <p className="col-lg-12" style={{ height: "60px" }}>
                <img
                  src="image/sell.gif"
                  alt=""
                  style={{ height: "100%", width: "100%" }}
                />
              </p>
            </a>
            <a
              href="https://www.xt.com/en/trade/wys_usdt"
              target="_black"
              className="col-lg-6"
            >
              <p className="col-lg-12" style={{ height: "60px" }}>
                <img
                  src="image/buy.gif"
                  alt=""
                  style={{ height: "100%", width: "100%" }}
                />
              </p>
            </a>
          </div>
        </div>

        <div
          className="row col-lg-10"
          style={{
            borderRadius: "13px",
            border: "1px solid var(--Gray-700, #313131)",
            height: "auto",
            margin: "20px auto",
            overflow: "auto",
          }}
          id="filterTable"
        >
          <table
            style={{
              width: "100%",
              color: "white",
              marginBottom: "10px",
              minWidth: "900px",
            }}
          >
            <thead>
              <tr>
                <th>Farm</th>
                <th>WYS/USDT Farm</th>
                <th>ARB/BNB/WYZ Farm</th>
                {/* <th>BNB Stake</th> */}
                <th>Total Amount</th>
                <th>Duration</th>
                {/* <th>Transaction</th> */}
                <th>Time</th>
              </tr>
            </thead>

            {userStakeData &&
              userStakeData?.map((data) => (
                <tbody>
                  <td>
                    {" "}
                    {data.planId == 1 && "WYS"}
                    {data.planId == 2 && "WYS:ARB"}
                    {data.planId == 3 && "WYS:BNB"}
                    {data.planId == 4 && "WYS:WYZ[50:50]"}
                    {data.planId == 5 && "WYS:WYZ[70:30]"}
                    {data.planId == 6 && "fUSDT"}
                  </td>
                  <td>
                    {/* {(data.wysAmount / 1e18).toFixed(6)}{" "} */}
                    {data.planId != 6 && (data.wysAmount / 1e18).toFixed(3)}
                    {data.planId == 6 && (data.wysAmount / 1e18).toFixed(3)}
                    {data.planId == 6 ? " fUSDT" : "WYS"}
                  </td>
                  <td>
                    {data.planId == 1 && "-"}
                    {data.planId == 2 &&
                      `${(data.otherAmt / 1e18).toFixed(3)} ARB`}
                    {data.planId == 3 &&
                      `${(data.otherAmt / 1e18).toFixed(3)} BNB`}
                    {data.planId == 4 &&
                      `${(data.otherAmt / 1e18).toFixed(3)} WYZ`}
                    {data.planId == 5 &&
                      `${(data.otherAmt / 1e18).toFixed(3)} WYZ`}
                    {data.planId == 6 &&
                      `${(data.otherAmt / 1e18).toFixed(3)} fUSDT`}
                  </td>
                  <td>
                    {data.planId != 6 && (data.ttlAmt / 1e18).toFixed(3)}
                    {data.planId == 6 && (data.ttlAmt / 1e18).toFixed(3)}
                  </td>
                  <td>{data.duration}</td>
                  <td>{moment(data.timestamp * 1000).fromNow()}</td>
                </tbody>
              ))}
          </table>

          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handleStakePageChange}
            variant="outlined"
            shape="rounded"
            color="primary"
            className="pagination1"
          />
        </div>

        <div
          className="row col-lg-11"
          style={{ margin: "20px auto" }}
          id="reward"
        >
          <h6 style={{ paddingLeft: "68px" }}>Rewards & Referrals </h6>
          <div className="row col-lg-5" style={{ margin: "5px auto" }}>
            <div
              className=" col-lg-11 claim"
              style={{ position: "relative", margin: "auto" }}
            >
              <span>
                {(dashboard?.availabelReward / 1e18)?.toFixed(4) > 0
                  ? (dashboard?.availabelReward / 1e18)?.toFixed(4)
                  : 0}{" "}
                WYS
              </span>
              <Link onClick={claimref}>Claim</Link>
              <p>Available Reward to Claim</p>
              <p>
                Claimed Reward:{" "}
                {(dashboard?.withdrawalReward / 1e18).toFixed(4) > 0
                  ? (dashboard?.withdrawalReward / 1e18).toFixed(4)
                  : 0}
              </p>
            </div>

            <div
              className=" col-lg-11 input"
              style={{ position: "relative", margin: "auto" }}
            >
              <input
                type="text"
                value={`https://registration.wyscale.com/?refID=${walleid}`}
                style={{ fontSize: "12px" }}
              />
              <span
                onClick={() =>
                  handleCopyClick(
                    `https://registration.wyscale.com/?refID=${walleid}`
                  )
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                >
                  <path
                    d="M23.625 3.5H9.625C9.39294 3.5 9.17038 3.59219 9.00628 3.75628C8.84219 3.92038 8.75 4.14294 8.75 4.375V8.75H4.375C4.14294 8.75 3.92038 8.84219 3.75628 9.00628C3.59219 9.17038 3.5 9.39294 3.5 9.625V23.625C3.5 23.8571 3.59219 24.0796 3.75628 24.2437C3.92038 24.4078 4.14294 24.5 4.375 24.5H18.375C18.6071 24.5 18.8296 24.4078 18.9937 24.2437C19.1578 24.0796 19.25 23.8571 19.25 23.625V19.25H23.625C23.8571 19.25 24.0796 19.1578 24.2437 18.9937C24.4078 18.8296 24.5 18.6071 24.5 18.375V4.375C24.5 4.14294 24.4078 3.92038 24.2437 3.75628C24.0796 3.59219 23.8571 3.5 23.625 3.5ZM17.5 22.75H5.25V10.5H17.5V22.75ZM22.75 17.5H19.25V9.625C19.25 9.39294 19.1578 9.17038 18.9937 9.00628C18.8296 8.84219 18.6071 8.75 18.375 8.75H10.5V5.25H22.75V17.5Z"
                    fill="white"
                  />
                </svg>
              </span>
            </div>
          </div>

          <div
            className="col-lg-5"
            style={{ margin: "10px auto", position: "relative" }}
          >
            <table
              style={{ width: "95%", color: "white", margin: "28px auto 5px " }}
              id="rewardTable"
            >
              <thead>
                <tr>
                  <th className="t"> Tier</th>
                  <th className="a">Tier Detail</th>
                </tr>
                <tr>
                  <th className="t">Referee Wallet </th>
                  <th className="a">
                    {dashboard && dashboard?.referrer.slice(0, 3)}...
                    {dashboard?.referrer.slice(-6)}
                  </th>
                </tr>
                <tr>
                  <th className="t">Direct Referral </th>
                  <th className="a">{dashboard && dashboard?.directCount}</th>
                </tr>
                <tr>
                  <th className="t">Team</th>
                  <th className="a">{dashboard?.teamCount}</th>
                </tr>
                <tr>
                  <th className="t">Team Business</th>
                  <th className="a">
                    {dashboard && (dashboard?.teamBusiness / 1e18).toFixed(4)}
                  </th>
                </tr>
                <tr>
                  <th className="t">Referral rate</th>
                  <th className="a">10 % </th>
                </tr>
                <tr>
                  <th className="t">Total Rewards</th>
                  <th className="a b">10 % WYS </th>
                </tr>
                <tr>
                  <th className="t">WYS Price</th>
                  <th className="a b">
                    {" "}
                    $ {""}
                    {wys?.toFixed(3)}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
